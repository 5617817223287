<template>
  <span v-if="profile.role != 'User'">
    <v-card class="mt-3 pa-3">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Session"
              :items="sessions"
              return-object
              item-text="name"
              v-model="selectedSession"
              @change="onSelectedSession"
            ></v-select>
          </v-col>
        </v-row>
        <span v-if="selectedSession">
          <v-row class="mb-3">
            <v-col cols="12" md="6">
              <v-text-field
                label="Prefix univers"
                v-model="selectedSession.universesParameters.universPrefix"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Prefix team"
                v-model="selectedSession.universesParameters.teamPrefix"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="univers in universes">
              <v-expansion-panel-header>
                <span class="d-flex align-center justify-space-between">
                  <h3 v-text="univers.name"></h3>
                  <span>
                    <v-btn color="secondary" icon>
                      <v-icon
                        @click.prevent.stop="openRenameUniversModal(univers)"
                        >mdi-pencil-outline</v-icon
                      >
                    </v-btn>
                    <v-btn color="accent" icon>
                      <v-icon @click.prevent.stop="addTeam(univers)"
                        >mdi-account-plus-outline</v-icon
                      >
                    </v-btn>
                    <v-btn color="error" icon>
                      <v-icon @click.prevent.stop="deleteUnivers(univers)"
                        >mdi-delete-outline</v-icon
                      >
                    </v-btn>
                  </span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table :headers="teamsHeaders" :items="univers.teams">
                  <template v-slot:item.actions="{ item }">
                    <span class="d-flex align-center justify-center">
                      <v-btn
                        color="secondary"
                        icon
                        @click.prevent.stop="openRenameTeamModal(item)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                      <v-btn
                        color="warning"
                        icon
                        @click.prevent.stop="resetTeamCode(item)"
                      >
                        <v-icon>mdi-lock-reset</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        icon
                        @click.prevent.stop="deleteTeam(univers, item)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </span>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <span class="mt-5 d-flex flex-column align-center justify-center">
            <v-btn class="my-3" color="accent" outlined @click="addUnivers">
              <v-icon>mdi-plus</v-icon>
              <b>Ajouter un univers</b>
            </v-btn>

            <span class="mt-5 d-flex flex-row align-center justify-center">
              <v-btn
                class="my-3"
                color="primary"
                outlined
                @click="saveUniverses"
              >
                <v-icon>mdi-floppy</v-icon>
                <b>Sauvegarder</b>
              </v-btn>
              <v-btn
                class="ma-2"
                color="success"
                outlined
                @click="exportCurrentCodes"
              >
                <v-icon>mdi-file-excel-outline</v-icon>
                <span>Exporter les codes</span>
              </v-btn>
            </span>
          </span>
        </span>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="universRenameDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Renommer l'univers</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de l'univers"
            v-model="universRenameName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="universRenameDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="renameUnivers">Renommer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="teamRenameDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Renommer l'équipe</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de l'équipe"
            v-model="teamRenameName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="teamRenameDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="renameTeam">Renommer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as XLSX from "xlsx/xlsx.mjs";

import Session from "@/classes/Session.js";
import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";
import Profile from "@/classes/Profile.js";

import { v4 as uuidv4 } from "uuid";

export default {
  name: "Teams",
  props: ["user", "profile"],
  data() {
    return {
      unsub: [],
      universesUnsub: [],
      sessions: [],
      selectedSession: null,
      teamsHeaders: [
        { text: "Nom", value: "name" },
        { text: "Code", value: "code" },
        { text: "", value: "actions", sortable: false },
      ],
      universes: [],

      universRenameName: "",
      universRenameDialog: false,
      universRenameSelected: null,

      teamRenameName: "",
      teamRenameDialog: false,
      teamRenameSelected: null,

      items: [],
    };
  },
  created() {
    this.unsub.push(
      Session.listenOnlySession((sessions) => {
        sessions.sort((a, b) => b.date - a.date);
        this.sessions = sessions;
      })
    );
  },
  methods: {
    getUniversIndex() {
      let tmp_index = 0;
      this.universes.forEach((univers) => {
        tmp_index += 1;
      });
      return tmp_index;
    },
    getTeamIndex() {
      let tmp_index = 0;
      this.universes.forEach((univers) => {
        tmp_index += univers.teams.length;
      });
      return tmp_index;
    },
    onSelectedSession() {
      if (this.selectedSession) {
        this.universesUnsub.forEach((universUnsub) => {
          if (typeof universUnsub == "function") {
            universUnsub();
          }
        });

        this.universesUnsub = [];

        this.universesUnsub.push(
          Univers.listenBySession(this.selectedSession.id, (universes) => {
            if (universes) {
              this.universes = universes;
              this.universes.sort((a, b) => (a.name < b.name ? -1 : 1));
              this.universes.forEach((univers) => {
                univers.teams = [];
                this.universesUnsub.push(
                  Team.listenByUnivers(univers.id, (teams) => {
                    if (teams) {
                      let tmp_teams = [...teams];
                      tmp_teams.sort((a, b) => (a.name < b.name ? -1 : 1));
                      univers.teams = tmp_teams;
                      this.$forceUpdate();
                    }
                  })
                );
              });
            }
          })
        );
      }
    },
    addUnivers() {
      let tmp_universes = [...this.universes];
      let tmp_univers = new Univers(
        null,
        this.selectedSession.universesParameters.universPrefix +
          "-" +
          this.formatIndex(this.getUniversIndex() + 1),
        this.selectedSession.id
      );
      tmp_univers.teams = [];
      tmp_universes.push(tmp_univers);
      this.universes = tmp_universes;
      this.$forceUpdate();
    },
    formatIndex(index) {
      return index < 10 ? "00" + index : index < 100 ? "0" + index : index;
    },
    deleteUnivers(univers) {
      if (univers.teams && univers.teams.length > 0) {
        Swal.fire({
          title: "Impossible de supprimer cet univers",
          text: "Cet univers contient encore des équipes",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        if (univers.id) {
          univers.delete();
        }
        let index = this.universes.indexOf(univers);
        let tmp_universes = [...this.universes];
        tmp_universes.splice(index, 1);
        this.universes = tmp_universes;
        this.$forceUpdate();
      }
    },
    openRenameUniversModal(univers) {
      this.universRenameName = univers.name;
      this.universRenameSelected = univers;
      this.universRenameDialog = true;
    },
    renameUnivers() {
      this.universRenameSelected.name = this.universRenameName;
      this.universRenameDialog = false;
      this.universRenameSelected = null;
      this.$forceUpdate();
    },
    addTeam(univers) {
      let code = uuidv4().replaceAll("-", "").substring(0, 10);
      let tmp_team = new Team(
        null,
        this.selectedSession.universesParameters.teamPrefix +
          "-" +
          this.formatIndex(this.getTeamIndex() + 1),
        code,
        "nom_par_defaut",
        "",
        "",
        univers.id,
        null,
        []
      );
      let tmp_teams = [...univers.teams];
      tmp_teams.push(tmp_team);
      univers.teams = tmp_teams;
      this.$forceUpdate();
    },
    async deleteTeam(univers, team) {
      let profiles = await Profile.getByTeam(team.id);

      if (profiles && profiles.length > 0) {
        Swal.fire({
          title: "Impossible de supprimer cette équipe",
          text: "Cette équipe contient encore des utilisateurs",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        if (team.id) {
          team.delete();
        }
        let index = univers.teams.indexOf(team);
        let tmp_teams = [...univers.teams];
        tmp_teams.splice(index, 1);
        univers.teams = tmp_teams;
        this.$forceUpdate();
      }
    },
    resetTeamCode(team) {
      team.resetCode();
      this.$forceUpdate();
    },

    openRenameTeamModal(team) {
      this.teamRenameName = team.name;
      this.teamRenameSelected = team;
      this.teamRenameDialog = true;
    },
    renameTeam() {
      this.teamRenameSelected.name = this.teamRenameName;
      this.teamRenameDialog = false;
      this.teamRenameSelected = null;
      this.$forceUpdate();
    },
    async saveUniverses() {
      console.log(this.universes);
      this.universes.forEach(async (univers) => {
        univers = await univers.save();
        univers.teams.forEach(async (team) => {
          team.univers = univers.id;
          team = await team.save();
        });
      });
      Swal.fire({
        title: "Univers/Équipes sauvegardés",
        icon: "success",
        confirmButtonText: "Ok",
      });
    },
    exportCurrentCodes() {
      let codes = [];
      for (let univers of this.universes) {
        for (let team of univers.teams) {
          codes.push({
            univers: univers.name,
            team: team.name,
            code: team.code,
          });
        }
      }

      let wb = XLSX.utils.book_new();
      let sheet = XLSX.utils.json_to_sheet(codes);
      XLSX.utils.book_append_sheet(wb, sheet, "Codes");
      XLSX.writeFile(wb, this.selectedSession.name + "_Codes" + ".xlsx");
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
